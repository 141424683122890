var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{attrs:{"item-key":"name","loading":_vm.loading,"loading-text":"Loading... Please wait","headers":_vm.headers,"items":_vm.reservations,"search":_vm.search},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-text-field',{staticClass:"mx-4",attrs:{"label":"Search"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})]},proxy:true},{key:"item.planned_trip.route.name",fn:function(ref){
var item = ref.item;
return [_c('a',{on:{"click":function($event){$event.stopPropagation();return _vm.displayRoute(item.planned_trip.route.id)}}},[_vm._v(_vm._s(item.planned_trip.route.name))])]}},{key:"item.customer.name",fn:function(ref){
var item = ref.item;
return [_c('a',{on:{"click":function($event){$event.stopPropagation();return _vm.displayCustomer(item.customer.id)}}},[_vm._v(_vm._s(item.customer.name))])]}},{key:"item.planned_trip.driver.name",fn:function(ref){
var item = ref.item;
return [_c('a',{on:{"click":function($event){$event.stopPropagation();return _vm.displayDriver(item.planned_trip.driver.id)}}},[_vm._v(_vm._s(item.planned_trip.driver.name))])]}},{key:"item.first_stop.name",fn:function(ref){
var item = ref.item;
return [_c('a',{on:{"click":function($event){$event.stopPropagation();return _vm.displayStop(item.first_stop.id)}}},[_vm._v(_vm._s(item.first_stop.name))])]}},{key:"item.last_stop.name",fn:function(ref){
var item = ref.item;
return [_c('a',{on:{"click":function($event){$event.stopPropagation();return _vm.displayStop(item.last_stop.id)}}},[_vm._v(_vm._s(item.last_stop.name))])]}},{key:"item.paid_price",fn:function(ref){
var item = ref.item;
return [_c('small',[_vm._v(_vm._s(Math.round(item.paid_price * 100) / 100))])]}},{key:"item.planned_trip.planned_date",fn:function(ref){
var item = ref.item;
return [_c('small',[_vm._v(_vm._s(_vm._f("moment")(item.planned_trip.planned_date,"LL")))]),_vm._v(" - "),_c('small',{staticClass:"text-muted"},[_vm._v(_vm._s(item.planned_start_time))])]}},{key:"item.created_at",fn:function(ref){
var item = ref.item;
return [_c('small',[_vm._v(_vm._s(_vm._f("moment")(item.created_at,"LL")))])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"depressed":"","small":"","density":"compact","color":"error","icon":true},on:{"click":function($event){_vm.cancelReservation(item, _vm.reservations.indexOf(item))}}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v(" mdi-bank-minus ")])],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }